import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINT } from 'src/app/app.settings';
import { Filter, FilterValue } from '../../core/models/filter.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BandiService {
  constructor(private http: HttpClient) {}


  getBandiSearch(text_filter: string, Filters: Filter[], page = 1) {
    let queryFilters = '';
    Filters.forEach((filter: Filter) => {
      const array_filter = [...filter.actived_value.values()];
      const filter_id = filter.id;
      const filter_value_join = array_filter.map((value: FilterValue) => value.id).join(',');
      if(filter_value_join!==""){
        queryFilters += `&${filter_id}=${filter_value_join}`;
      }
    });

    if(text_filter!==""){
        queryFilters += `&text=${text_filter}`;
    }
    return this.http.get(
      `${API_ENDPOINT}/api/bandi/ricerca?${queryFilters}&page=${page}`
    );
  }
  getBandoById(id: string): Observable<any> {
    return this.http.get(`${API_ENDPOINT}/api/bando/${id}`);
  }
  public getPdfBando(bando_id:string): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/api/file/pdf/anteprima?id_bando=${bando_id}`,{});
  }

}
