import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icona-stato-bandi',
  templateUrl: './icona-stato-bandi.component.html',
  styleUrls: ['./icona-stato-bandi.component.scss']
})
export class IconaStatoBandiComponent {
  @Input() status!: number;
}
