import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GoBackBtnComponent } from './go-back-btn/go-back-btn.component';
import { MatIconModule } from '@angular/material/icon';
import { GoBackGuardDialogComponent } from './go-back-btn/go-back-guard-dialog/go-back-guard-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IconaStatoBandiComponent } from './icona-stato-bandi/icona-stato-bandi.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FiltroRicercaBandiSingoloComponent } from './filtro-ricerca-bandi-singolo/filtro-ricerca-bandi-singolo.component';
@NgModule({
  declarations: [
    GoBackGuardDialogComponent,
    IconaStatoBandiComponent,
    FiltroRicercaBandiSingoloComponent,
  ],
  exports: [
    GoBackGuardDialogComponent,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatExpansionModule,
    MatPaginator,
    MatSlideToggleModule,
    IconaStatoBandiComponent,
    MatTabsModule,
    FiltroRicercaBandiSingoloComponent,
    GoBackBtnComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatExpansionModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTabsModule,
    GoBackBtnComponent,
  ],
})
export class SharedModule {}
