import { Component, OnInit } from '@angular/core';
import { TipologicheService } from '../../../core/services/tipologiche/tipologiche.service';
import { forkJoin } from 'rxjs';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { GestioneRichiesteService } from '../gestione-richieste.service';
import { ActivatedRoute } from '@angular/router';
import {
  TipologicaBase,
  TipologicaBeneficiariRichieste,
  TipologicaCamereDiCommercio,
  TipologicaClassiDiFatturato,
  TipologicaDimensioniInvestimento,
  TipologicaFinalita,
  TipologicaSettori,
} from 'src/app/core/models/tipologiche.model';
import { TranslateService } from '@ngx-translate/core';
import { GoBackGuardDialogComponent } from '../../shared/go-back-btn/go-back-guard-dialog/go-back-guard-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {UserSessionService} from "../../../core/services/user-session/user-session.service";
import { LANG_FLAGS_CCIAA } from 'src/app/app.settings';
import { calculateColorMessage } from 'src/app/core/utils/calc_color_message_len';

@Component({
  selector: 'app-richiedi-agevolazioni',
  templateUrl: './richiedi-agevolazioni.component.html',
  styleUrls: ['./richiedi-agevolazioni.component.scss'],
})
export class RichiediAgevolazioniComponent implements OnInit {
  constructor(
    private tipologicheService: TipologicheService,
    private fb: FormBuilder,
    private gestioneRichiesteService: GestioneRichiesteService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog,
    private userSessionService: UserSessionService,
  ) {
    this.cameraDICommercioPreselezionata = +this.activatedRoute.snapshot.queryParams['cameraDiCommercio'];

    this.richiediAgevolazioniForm.patchValue({ cameraDiCommercio: this.cameraDICommercioPreselezionata, });
    this.translate.addLangs(['it', 'de']);
    this.translate.setDefaultLang('it');
  }

  cameraDICommercioPreselezionata?: number;
  richiediAgevolazioniForm = this.fb.group({
    aspiranteImprenditore: [false],
    beneficiario: ['', Validators.compose([Validators.required])],
    classeDiFatturato: ['', Validators.compose([Validators.required])],
    ragioneSociale: ['', Validators.compose([Validators.required])],
    codiceFiscale: [
      '',
      Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(16)]),
    ],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    telefono: ['', Validators.compose([])],
    cameraDiCommercio: [0, Validators.compose([Validators.required])],
    settori: [[] as number[], Validators.compose([Validators.required])],
    finalita: [[] as number[], Validators.compose([Validators.required])],
    dimensioneInvestimento: ['', Validators.compose([Validators.required])],
    descrizione: ['', Validators.compose([Validators.required])],
    checkPrivacy: ['', Validators.compose([Validators.required, Validators.requiredTrue])],
    checkMarketingUnionCamere: [null, Validators.compose([Validators.required])],
    checkMarketingInnexta: [null, Validators.compose([Validators.required])],
  });

  listaCamere: TipologicaCamereDiCommercio[] = [];
  listaSettori: TipologicaSettori[] = [];
  listaClassiDiFatturato: TipologicaClassiDiFatturato[] = [];
  listaFinalita: TipologicaFinalita[] = [];
  listaDimensioniInvestimento: TipologicaDimensioniInvestimento[] = [];
  listaBeneficiari: TipologicaBeneficiariRichieste[] = [];

  selectLang: string = 'it';
  TransLang: any = [];
  embedded: boolean = false;

  cameraDiCommercioFallback?: number;

  descrizioneMaxLength = 4000;
  public getDescrizioneLength(): number {
    return this.richiediAgevolazioniForm.value.descrizione?.length ?? 0;
  }
  public calculateColorMessage(): string {
    return calculateColorMessage(this.richiediAgevolazioniForm.value.descrizione, this.descrizioneMaxLength);
  }

  checkAspiranteImprenditore() {
    const isAspirante = this.richiediAgevolazioniForm.value.aspiranteImprenditore;

    if (isAspirante) {
      this.richiediAgevolazioniForm.controls.beneficiario.disable();
      this.richiediAgevolazioniForm.controls.classeDiFatturato.disable();
      this.richiediAgevolazioniForm.controls.ragioneSociale.disable();
      this.richiediAgevolazioniForm.controls.finalita.disable();

      this.richiediAgevolazioniForm.patchValue(
        {
          beneficiario: '',
          classeDiFatturato: '',
          ragioneSociale: null,
          finalita: [],
        },
        { emitEvent: false }
      );

      this.richiediAgevolazioniForm.controls.beneficiario.setValidators([]);
      this.richiediAgevolazioniForm.controls.classeDiFatturato.setValidators([]);
      this.richiediAgevolazioniForm.controls.ragioneSociale.setValidators([]);
      this.richiediAgevolazioniForm.controls.finalita.setValidators([]);

      this.richiediAgevolazioniForm.controls.codiceFiscale.setValidators([
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(16),
      ]);
    } else {
      this.richiediAgevolazioniForm.controls.beneficiario.enable();
      this.richiediAgevolazioniForm.controls.classeDiFatturato.enable();
      this.richiediAgevolazioniForm.controls.ragioneSociale.enable();
      this.richiediAgevolazioniForm.controls.finalita.enable();

      this.richiediAgevolazioniForm.controls.beneficiario.setValidators([Validators.required]);
      this.richiediAgevolazioniForm.controls.classeDiFatturato.setValidators([Validators.required]);
      this.richiediAgevolazioniForm.controls.ragioneSociale.setValidators([Validators.required]);
      this.richiediAgevolazioniForm.controls.finalita.setValidators([Validators.required]);

      this.richiediAgevolazioniForm.controls.codiceFiscale.setValidators([
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(16),
      ]);
    }
    this.richiediAgevolazioniForm.controls.beneficiario.updateValueAndValidity({
      emitEvent: false,
    });
    this.richiediAgevolazioniForm.controls.classeDiFatturato.updateValueAndValidity({
      emitEvent: false,
    });
    this.richiediAgevolazioniForm.controls.ragioneSociale.updateValueAndValidity({
      emitEvent: false,
    });
    this.richiediAgevolazioniForm.controls.finalita.updateValueAndValidity({ emitEvent: false });
    this.richiediAgevolazioniForm.controls.codiceFiscale.updateValueAndValidity({
      emitEvent: false,
    });

    //email, telefono, Camera di commercio, dimensione investimento, Descrizione del progetto e privacy devono essere sempre abilitati
  }
  bandiere_attive:Array<string> = [];
  submitted = false;
  ngOnInit(): void {
    this.embedded = window.location.href.indexOf("embedded") != -1;
    const joinReqTipologiche = forkJoin({
      camere: this.tipologicheService.getTipologicaCamere(),
      settori: this.tipologicheService.getTipologicaSettori(this.selectLang),
      classiDiFatturato: this.tipologicheService.getTipologicaClasseDiFatturato(),
      finalita: this.tipologicheService.getTipologicaFinalita(this.selectLang),
      dimensioniInvestimento: this.tipologicheService.getTipologicaDimensioneInvestimento(),
      beneficiari: this.tipologicheService.getTipologicaBeneficiariRichieste(this.selectLang),
    });
    joinReqTipologiche.subscribe((data: any) => {
      this.listaCamere = data.camere.filter((camera: TipologicaCamereDiCommercio) => this.embedded ? camera.attivo : camera.accreditata);
      
      Object.keys(LANG_FLAGS_CCIAA).forEach((lang: string) => {
        if (LANG_FLAGS_CCIAA[lang] == undefined || LANG_FLAGS_CCIAA[lang]== null || LANG_FLAGS_CCIAA[lang] == false) 
          return;
        if(Array.isArray(LANG_FLAGS_CCIAA[lang]) &&  LANG_FLAGS_CCIAA[lang].length === 0 ) return;

        if(Array.isArray(LANG_FLAGS_CCIAA[lang]) && LANG_FLAGS_CCIAA[lang].length > 0){
          //if lang is array and has value that is in listaCamere set true else false
          const camereTrovate = LANG_FLAGS_CCIAA[lang].filter((cameraId: number) => {
            if(this.listaCamere.find((camera: any) => camera.id == cameraId)) return true;
            return false;
          });
          if(camereTrovate.length === 0) return;
        }
        this.bandiere_attive.push(lang);
      });
      

      this.listaSettori = data.settori;
      this.listaClassiDiFatturato = data.classiDiFatturato;
      this.listaFinalita = data.finalita;
      this.listaDimensioniInvestimento = data.dimensioniInvestimento;
      this.listaBeneficiari = data.beneficiari;
      if ((this.userSessionService.getUserData()?.cameraDiCommercio?.id) && (!this.embedded)){
        this.richiediAgevolazioniForm.patchValue({
          cameraDiCommercio: this.userSessionService.getUserData().cameraDiCommercio.id
        });
        this.richiediAgevolazioniForm.controls.cameraDiCommercio.disable();
      }
    });
    this.translate.use(this.selectLang);
    this.initTranslation();
    this.getTransLanguage();
  }

  submittedSuccessfully = false;
  onSubmitRichiesta() {
    this.submitted = true;

    if (this.richiediAgevolazioniForm.invalid) return;

    if (this.userSessionService.getUserData()?.cameraDiCommercio?.id){
      console.log("force camera",this.userSessionService.getUserData().cameraDiCommercio.id);
      this.richiediAgevolazioniForm.value.cameraDiCommercio = this.userSessionService.getUserData().cameraDiCommercio.id;
    }

    console.log(this.richiediAgevolazioniForm.value);

    const reqBody = {
      id_camera: this.richiediAgevolazioniForm.value.cameraDiCommercio ?? this.cameraDiCommercioFallback,
      id_beneficiario: this.richiediAgevolazioniForm.value.beneficiario,
      ragione_sociale: this.richiediAgevolazioniForm.value.ragioneSociale,
      codice_fiscale: this.richiediAgevolazioniForm.value.codiceFiscale,
      email: this.richiediAgevolazioniForm.value.email,
      telefono: this.richiediAgevolazioniForm.value.telefono,
      descrizione: this.richiediAgevolazioniForm.value.descrizione,
      finalita: this.richiediAgevolazioniForm.value.finalita,
      settori: this.richiediAgevolazioniForm.value.settori,
      privacy: this.richiediAgevolazioniForm.value.checkPrivacy,
      marketing_innexta: this.richiediAgevolazioniForm.value.checkMarketingInnexta,
      marketing_unioncamere: this.richiediAgevolazioniForm.value.checkMarketingUnionCamere,
      classe_fatturato:
        this.findTipologicaAttributeFromArrayById(
          this.listaClassiDiFatturato,
          this.richiediAgevolazioniForm.value.classeDiFatturato!
        )?.nome || null,
      dimensione_investimento:
        this.findTipologicaAttributeFromArrayById(
          this.listaDimensioniInvestimento,
          this.richiediAgevolazioniForm.value.dimensioneInvestimento!
        )?.nome || null,
      aspirante_imprenditore: this.richiediAgevolazioniForm.value.aspiranteImprenditore,
      test: !this.embedded
    };
    console.log(reqBody);
    this.gestioneRichiesteService.submitRichiesta(reqBody).subscribe(() => {
      this.submittedSuccessfully = true;
    });
  }
  findTipologicaAttributeFromArrayById(array: TipologicaBase[], id: number | string) {
    return array.find(el => el.id == id);
  }

  checkFieldIsValid(form: any, formFieldName: string) {
    //if (!this.submitted) return true;
    if (form.controls[formFieldName] == undefined) return true;

    if (form.controls[formFieldName].valid) return true;

    const formField = form.controls[formFieldName];
    if (formField.dirty && form.controls[formFieldName].invalid) return false;
    if (this.submitted && form.controls[formFieldName].invalid) return false;
    return true;
  }

  setTransLanguage(language: string) {
    if (this.selectLang != language && !this.richiediAgevolazioniForm.pristine) {
      const dialogRef = this.dialog.open(GoBackGuardDialogComponent, {
        data: {
          guard_message: "Sei sicuro di voler andare avanti ? Le modifiche non salvate andranno perse. / Sind Sie sicher, dass Sie die Sprache ändern wollen? Nicht gespeicherte Änderungen werden gelöscht.",
          confirm_button_text: 'Conferma / Bestätigen',
          cancel_button_text: 'Annulla / Abbrechen',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.action) {
          this.richiediAgevolazioniForm.controls.beneficiario.enable();
          this.richiediAgevolazioniForm.controls.classeDiFatturato.enable();
          this.richiediAgevolazioniForm.controls.ragioneSociale.enable();
          this.richiediAgevolazioniForm.controls.finalita.enable();
          this.richiediAgevolazioniForm.controls.cameraDiCommercio.enable();
          this.richiediAgevolazioniForm.reset();
          this.changeLanguage(language);
        }
      });
    } else {
      this.changeLanguage(language);
    }
  }
  getTransLanguage() {
    this.TransLang = [...this.translate.getLangs()];
  }

  translation: Record<string,string> = {};
  initTranslation() {
    this.translate
      .get([
        'agevolazioni.titolo',
        'agevolazioni.sottotitolo',
        'agevolazioni.descrizione',
        'agevolazioni.descrizione2',
        'agevolazioni.aspiranteImprenditore',
        'agevolazioni.chiSei',
        'agevolazioni.classeFatturato',
        'agevolazioni.ragioneSociale',
        'agevolazioni.codiceFiscale',
        'agevolazioni.email',
        'agevolazioni.telefono',
        'agevolazioni.cameraCommercio',
        'agevolazioni.settori',
        'agevolazioni.agevolazioni',
        'agevolazioni.dimensioneInvestimento',
        'agevolazioni.ideaBusiness',
        'agevolazioni.consensoPrivacy',
        'agevolazioni.privacy',
        'agevolazioni.consensoEventi',
        'agevolazioni.consensoComunicazioni',
        'agevolazioni.informazioniGenerali',
        'agevolazioni.informazioniPrivacy',
        'agevolazioni.cliccaQui',
        'agevolazioni.si',
        'agevolazioni.no',
        'agevolazioni.inviaRichiesta',
        'agevolazioni.messaggioCambioLingua',
        'agevolazioni.indicaAttivitaObiettivi',
        'agevolazioni.codiceFiscaleValido',
        'agevolazioni.ragioneSocialeValida',
        'agevolazioni.emailValida',
        'agevolazioni.inserisciLaTuaIdea',
        'agevolazioni.accettaLaPrivacy',
        'agevolazioni.accettaComunicazioniCamCom',
        'agevolazioni.accettaComunicazioniInnexta',
        'agevolazioni.nomeFileInformativaPrivacy',
        'agevolazioni.nomeFilePolitichePrivacy',
        'agevolazioni.nomeFileCondizioniGenerali',
        'agevolazioni.richiestaInviataConSuccesso',
        'agevolazioni.caratteriCounter'
      ])
      .subscribe(translations => this.translation = translations);
  }
  translateText(key: string) {
    return this.translation[key] ?? key;
  }

  changeLanguage(language: string) {
    this.selectLang = language;
    this.translate.use(this.selectLang);
    this.initTranslation();

    const joinReqTipologiche = forkJoin({
      settori: this.tipologicheService.getTipologicaSettori(language),
      finalita: this.tipologicheService.getTipologicaFinalita(language),
      beneficiari: this.tipologicheService.getTipologicaBeneficiariRichieste(language),
      camereDiCommercio: this.tipologicheService.getTipologicaCamere()
    });
    joinReqTipologiche.subscribe(data => {
      this.listaSettori = data.settori;
      this.listaFinalita = data.finalita;
      this.listaBeneficiari = data.beneficiari;
      this.submitted = false;
      this.listaCamere = data.camereDiCommercio.filter((camera: TipologicaCamereDiCommercio) => this.embedded ? camera.attivo : camera.accreditata);


      this.listaCamere = this.listaCamere.filter((camera: any) => {
        if(Array.isArray(LANG_FLAGS_CCIAA[language]) && LANG_FLAGS_CCIAA[language].includes(camera.id)) return true;
        if(LANG_FLAGS_CCIAA[language] == true) return true;
        return false;
      });

      if (this.listaCamere.length === 1 ) {
        const idCameraDiCommercio = this.listaCamere[0].id as number;
        this.richiediAgevolazioniForm.controls.cameraDiCommercio.disable({emitEvent: false});
        this.richiediAgevolazioniForm.controls.cameraDiCommercio.patchValue(idCameraDiCommercio, {emitEvent: false});
        this.cameraDiCommercioFallback = idCameraDiCommercio;
      }else {
        this.richiediAgevolazioniForm.controls.cameraDiCommercio.enable();
        this.richiediAgevolazioniForm.controls.cameraDiCommercio.reset();

      }

      if(this.userSessionService.getUserData()?.cameraDiCommercio?.id){
        this.richiediAgevolazioniForm.patchValue({cameraDiCommercio: this.userSessionService.getUserData().cameraDiCommercio.id});
        this.richiediAgevolazioniForm.controls.cameraDiCommercio.disable();
      }

    });    
  }
  
}
