export const environment = {
  production: false,
  API_ENDPOINT: '/backend',
  API_ASSET_ENDPOINT: '/backend/storage',
  ENVIRONMENT_NAME: 'preview',
  LANG_FLAGS_CCIAA: {
    it: true, //tutte le camere
    de: [
        13 // Bolzano
    ],
  }
};
