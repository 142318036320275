import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from 'src/app/app.settings';
import {
  TipologicaBeneficiariRichieste,
  TipologicaCamereDiCommercio,
  TipologicaCamereDiCommercioEdit,
  TipologicaFinalita,
  TipologicaSettori,
} from '../../models/tipologiche.model';
const dimensioniInvestimento = [
  { id: 1, nome: '0 - 50.000' },
  { id: 2, nome: '51.000 - 100.000' },
  { id: 3, nome: '101.000 - 500.000' },
  { id: 4, nome: '501.000 - 1.000.000' },
  { id: 5, nome: '1.001.000 - 5.000.000' },
  { id: 6, nome: '5.001.000 - 10.000.000' },
  { id: 7, nome: '10.001.000 - 25.000.000' },
];
const classiDiFatturato = [
  { id: 1, nome: '0 - 500.000' },
  { id: 2, nome: '500.001 - 1.000.000' },
  { id: 3, nome: '1.000.001 - 1.500.000' },
  { id: 4, nome: '1.500.001 - 2.000.000' },
  { id: 5, nome: '2.000.001 - 10.000.000' },
  { id: 6, nome: '10.000.001 - 50.000.000' },
  { id: 7, nome: '> 50.000.000' },
];

@Injectable({
  providedIn: 'root',
})
export class TipologicheService {
  constructor(private http: HttpClient) { }

  getTipologicaDimensioneInvestimento() {
    return new Observable(observer => {
      observer.next(dimensioniInvestimento);
      observer.complete();
    });
  }
  getTipologicaClasseDiFatturato() {
    return new Observable(observer => {
      observer.next(classiDiFatturato);
      observer.complete();
    });
  }
  getTipologicaCamere() {
    return this.http.get<Array<TipologicaCamereDiCommercio>>(
      `${API_ENDPOINT}/api/tipologiche/camere`
    );
  }
  getTipologicaSettori(language: string) {
    return this.http.get<Array<TipologicaSettori>>(`${API_ENDPOINT}/api/tipologiche/settori?langCode=` + language);
  }
  getTipologicaFinalita(language: string) {
    return this.http.get<Array<TipologicaFinalita>>(`${API_ENDPOINT}/api/tipologiche/finalita?langCode=` + language);
  }
  getTipologicaBeneficiariRichieste(language: string) {
    return this.http.get<Array<TipologicaBeneficiariRichieste>>(
      `${API_ENDPOINT}/api/tipologiche/beneficiari-richieste?langCode=` + language
    );
  }

  getTipologicaByModels(models: string, page: number, nameSearch?: string) {
    return this.http.get(`${API_ENDPOINT}/api/tipologiche?models=${models}&page=${page}&nome=${nameSearch}`);
  }
  getTipologicaCamereDiCommercio() {
    return this.http.get(`${API_ENDPOINT}/api/tipologiche?models=CameraDiCommercio&maxElForPage=true`);
  }
  editMultipleAttributesTipologicaByMap(
    model: string,
    listaAttributiModificati: Map<number | string, boolean>
  ) {
    const newReqBody = {
      models: [
        {
          name: model,
          entity: [] as { id: number | string; state: boolean }[],
        },
      ],
    };
    listaAttributiModificati.forEach((value, key) => {
      newReqBody.models[0].entity.push({ id: key, state: value });
    });
    return this.http.post(`${API_ENDPOINT}/api/tipologiche/modifica`, newReqBody);
  }

  editSingleTipologicaAttributo(model: string, idAttributo: number, valoreCambiato: boolean) {
    const newReqBody = {
      models: [
        {
          name: model,
          entity: [{ id: idAttributo, state: valoreCambiato }],
        },
      ],
    };
    return this.http.post(`${API_ENDPOINT}/api/tipologiche/modifica`, newReqBody);
  }

  getSingleTipologicaCameraDiCommercioById(id: number) {
    return this.http.get<TipologicaCamereDiCommercioEdit>(
      `${API_ENDPOINT}/api/tipologiche/camera?id=${id}`
    );
  }
  editSingleTipologicaCameraDiCommercioById(
    id: number,
    reqBody: any
  ): Observable<TipologicaCamereDiCommercio> {
    return this.http.post<TipologicaCamereDiCommercio>(
      `${API_ENDPOINT}/api/amministrazione/modifica-camere?id=${id}`,
      reqBody
    );
  }
}
