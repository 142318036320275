<div class="flex flex-col m-5 gap-5">
  <p>{{ data.guard_message }}</p>
  <div class="flex flex-wrap justify-between">
    <button
      class="bg-innexta-fucsia text-white rounded-md p-2 hover:shadow-2xl"
      (click)="onConfirmClick()">
      {{data.confirm_button_text || 'Conferma'}}
    </button>
    <button
      class="bg-innexta-cyan text-white rounded-md p-2 hover:shadow-2xl"
      (click)="onCancelClick()">
      {{data.cancel_button_text || 'Annulla'}}
    </button>
  </div>
</div>
