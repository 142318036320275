import { CanActivateFn, Router } from '@angular/router';
import { UserSessionService } from '../services/user-session/user-session.service';
import { inject } from '@angular/core';

export const authenticatedGuardGuard: CanActivateFn = () => {
  const userSessionService = inject(UserSessionService);
  const router = inject(Router);
  if (!userSessionService.isLogged()) {
    router.navigate(['/signin']);
    return false;
  }
  
  return userSessionService.isLogged();
};
