<div class="flex flex-col gap-2 justify-end" [formGroup]="formgroup">
  <label [for]="nomecampo" class="inline-flex items-center input-label-style"
    >{{ label }}
    <mat-icon
      *ngIf="tooltip"
      fontIcon="question_mark"
      [matTooltip]="tooltip"
      matTooltipPosition="above"
      class="text-innexta-fucsia rounded-full bg-white text-base h-5 w-5 ml-2 text-center"
      >question_mark</mat-icon
    >
  </label>
  <mat-select
    [id]="nomecampo"
    class="input-style w-full"
    [multiple]="multiple"
    [formControlName]="formcontrolname"
    [class.readonly]="readonly">
    <mat-option *ngIf="enabledSearch">
      <ngx-mat-select-search
        noEntriesFoundLabel="Nessun risultato"
        placeholderLabel="Cerca..."
        [ngModelOptions]="{ standalone: true }"
        ngModel
        (ngModelChange)="search($event)"></ngx-mat-select-search>
    </mat-option>
    <mat-option
      [disabled]="
        maxselection
          ? checkNumberSelectedIsUnderMax( el.id)
          : false
      "
      *ngFor="let el of arrayelFiltered"
      [value]="el.id">
      {{ el.nome }}
    </mat-option>
  </mat-select>

  <p
    class="text-xs text-rosso-scaduto"
    [class.invisible]="checkFieldIsValid(formgroup, formcontrolname)">
    {{ multiple ? selezionareAlmenoUnaVoce : selezionareUnaVoce }}
  </p>
</div>
