import { environment } from '../environments/environment';

export const API_ENDPOINT = environment.API_ENDPOINT;
export const APP_TITLE = 'Portale Agevolazioni';
export const ENVIRONMENT_NAME = environment.ENVIRONMENT_NAME;

export const API_ASSET_ENDPOINT = environment.API_ASSET_ENDPOINT;

export const API_IMAGE_ENDPOINT = `${API_ASSET_ENDPOINT}/images`;
export const API_IMAGE_ENDPOINT_CAMERE = `${API_IMAGE_ENDPOINT}/camere`;
export const LANG_FLAGS_CCIAA: any = environment.LANG_FLAGS_CCIAA;
export const AppSettings = {
  API_ENDPOINT,
  APP_TITLE,
  ENVIRONMENT_NAME,
  API_ASSET_ENDPOINT,
  API_IMAGE_ENDPOINT,
  API_IMAGE_ENDPOINT_CAMERE,
  LANG_FLAGS_CCIAA
};
