import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FulfilRichiesta,
  Interlocuzione,
  InterlocuzioneLista,
  InterlocuzioneOne2one,
} from '../../core/models/interlocuzione';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINT } from 'src/app/app.settings';

interface PaginazioneListaRichieste {
  currentPage: number;
  totalPage: number;
  elementPerPage: number;
  totalElement: number;
  dati: Array<InterlocuzioneLista>;
}

@Injectable({
  providedIn: 'root',
})
export class GestioneRichiesteService {


  storeOne2one(idInterlocuzione: number, intelocuzioneOne2one?: InterlocuzioneOne2one) {
    return this.http.post<InterlocuzioneOne2one>(`${API_ENDPOINT}/api/operatore/richieste/${idInterlocuzione}/one2one`, intelocuzioneOne2one);
  }
  getOne2one(idOne2one: number): Observable<InterlocuzioneOne2one> {
    return this.http.get<InterlocuzioneOne2one>(`${API_ENDPOINT}/api/operatore/richieste/one2one/${idOne2one}`);
  }
  getFileAllegato(idRichiesta:number, filename: string): Observable<Blob> {
    return this.http.get(`${API_ENDPOINT}/api/operatore/richieste/${idRichiesta}/one2one/documento/${filename}`, { responseType: 'blob' });
  }

  constructor(private http: HttpClient) {}

  public downloadXlsxRichieste(
    testo = '',
    stato = 0,
    direzione = 'asc',
    chiaveOrdinamento = 'data_creazione',
    operatore = '',
    cameraDiCommercio: string = "",
    cameraCommercioAbilitata: boolean = false
  ) {
    let statoParam = `&stato=${stato}`;
    //let cameraDiCommercioParam = `&camera=${cameraDiCommercio}`;
    let cameraDiCommercioParam = "&camera=" + cameraDiCommercio;
    if (stato == -1) statoParam = '';
    //if (cameraDiCommercio == -1) cameraDiCommercioParam = '';

    return this.http.get(
      `${API_ENDPOINT}/api/operatore/richieste/export?testo=${testo}&direzione=${direzione}&ordina=${chiaveOrdinamento}${statoParam}${cameraDiCommercioParam}&operatore=${operatore}&cameraCommercioAbilitata=${cameraCommercioAbilitata}`,
      { responseType: 'blob' }
    );
  }
  public getRichieste(
    testo = '',
    stato = 0,
    direzione = 'asc',
    chiaveOrdinamento = 'data_creazione',
    page: number = 1,
    operatore = '',
    cameraDiCommercio: number = -1,
    cameraCommercioAbilitata: boolean = false
  ) {
    console.log("cameraCommercioAbilitata: " + cameraCommercioAbilitata);
    let statoParam = `&stato=${stato}`;
    let cameraDiCommercioParam = `&camera=${cameraDiCommercio}`;
    if (stato == -1) statoParam = '';
    if (cameraDiCommercio == -1) cameraDiCommercioParam = '';
    return this.http.get<PaginazioneListaRichieste>(
      `${API_ENDPOINT}/api/operatore/richieste/ricerca?testo=${testo}&direzione=${direzione}&ordina=${chiaveOrdinamento}${statoParam}${cameraDiCommercioParam}&operatore=${operatore}&page=${page}&cameraCommercioAbilitata=${cameraCommercioAbilitata}`
    );
  }
  public getRichiestaById(id: number): Observable<Interlocuzione> {
    return this.http.get<Interlocuzione>(`${API_ENDPOINT}/api/operatore/richiesta/${id}`);
  }
  public submitRichiesta(richiesta: any): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/api/richiedi-agevolazioni`, richiesta);
  }
  public submitFulfilRichiesta(fulfilRichiesta: FulfilRichiesta): Observable<any> {
    if (fulfilRichiesta.bandi?.length == 0) {
      delete fulfilRichiesta.bandi;
    }
    return this.http.post(`${API_ENDPOINT}/api/operatore/evadi-richiesta`, fulfilRichiesta);
  }

  public getRichiesteDaPorteEvadere(
    testo = '',
    direzione = 'asc',
    chiaveOrdinamento = 'data_creazione',
    page: number = 1
  ) {
    return this.http.get<PaginazioneListaRichieste>(
      `${API_ENDPOINT}/api/operatore/richieste/ricerca?stato=0,2&testo=${testo}&direzione=${direzione}&ordina=${chiaveOrdinamento}&page=${page}`
    );
  }
  public getPdfRichiesta(id: number): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/api/file/pdf/anteprima?id_interlocuzione=${id}`, {});
  }

  public eliminaRichiesta(idRichiesta: number): Observable<any> {
    return this.http.get(`${API_ENDPOINT}/api/operatore/richiesta-elimina/` + idRichiesta);
  }
}
