import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
interface DialogDataGuard {
  guard_message: string;
  confirm_button_text: string;
  cancel_button_text: string;
}
interface DialogDataGuardReturn {
  action: boolean;
}
@Component({
  selector: 'app-go-back-guard-dialog',
  templateUrl: './go-back-guard-dialog.component.html',
  styleUrls: ['./go-back-guard-dialog.component.scss'],
})
export class GoBackGuardDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataGuard,
    public dialogRef: MatDialogRef<GoBackGuardDialogComponent>
  ) {}

  onConfirmClick(): void {
    this.dialogRef.close({ action: true });
  }
  onCancelClick(): void {
    this.dialogRef.close({ action: false });
  }
}
