export function calculateColorMessage(messaggio: string|null|undefined, maxLenght: number = 4000): string {

    if (!messaggio) return '';
    if (messaggio.length >= ((maxLenght / 100) * 95)) {
        return 'text-rosso-scaduto';
    }
    if (messaggio.length >= ((maxLenght / 100) * 80)) {
        return 'text-orange-500';
    }
    return '';
}
