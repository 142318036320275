<div class="flex lg:flex-row flex-col custom-container m-auto my-10">
  <div
    class="bg-innexta-gradient-top-down lg:w-1/3 w-full flex flex-col items-center rounded-t-3xl lg:rounded-tr-none lg:rounded-l-3xl">
    <h1 class="text-white font-bold text-4xl text-center mt-4 lg:mt-44">{{translateText('agevolazioni.titolo')}}</h1>
    <h1 class="text-white font-bold text-4xl text-center mb-10">{{translateText('agevolazioni.sottotitolo')}}</h1>
    <p class="text-white text-center text-xl w-5/6 font-light">
      {{translateText('agevolazioni.descrizione')}}
    </p>
    <div><br></div>
    <p class="text-white text-center text-lg w-5/6 font-light">{{translateText('agevolazioni.descrizione2')}}</p>
    <img
      src="../../../../assets/svg/omino-richiedi-agevolazioni.svg"
      alt="Richiedi agevolazioni"
      class="w-44 mt-6 lg:absolute bottom-0" />
  </div>
  <div class="bg-white w-full rounded-r-3xl lg:pl-10">

    <div class="flex flex-row gap-2" *ngIf="bandiere_attive.length >=2">
      @for(bandiera of bandiere_attive; track bandiera){
        <div class="inline-block w-[22px]" [class.bg-innexta-cyan]="bandiera === selectLang" ><span class="fi pointer" [ngClass]="'fi-'+ bandiera" (click)="setTransLanguage(bandiera)"></span></div>
      }
    </div>
    <form
      class="flex flex-col gap-2 relative justify-between my-3 bg-white p-4"
      [formGroup]="richiediAgevolazioniForm">
      <div class="inline-flex gap-2">
        <!-- label for="aspiranteImprenditore" class="input-label-style"
          >Seleziona il flag se sei un aspirante Imprenditore o un’aspirante Imprenditrice
        </label -->
        <label for="aspiranteImprenditore" class="input-label-style"
          >{{translateText('agevolazioni.aspiranteImprenditore')}}
        </label>
        <input
          type="checkbox"
          class="border-2 border-solid border-innexta-cyan p-4 w-5 h-5"
          [class.readonly]="submittedSuccessfully"
          formControlName="aspiranteImprenditore"
          (change)="checkAspiranteImprenditore()"
          id="aspiranteImprenditore" />
      </div>
      <div class="form-grid">
        <!-- div>{{chiSei}}</div -->
        <app-select-search
          [enabledSearch]="true"
          [readonly]="submittedSuccessfully"
          [label]="translateText('agevolazioni.chiSei')"
          nomecampo="chisei"
          formcontrolname="beneficiario"
          [arrayel]="listaBeneficiari"
          [formgroup]="richiediAgevolazioniForm"
          [checkFieldIsValid]="checkFieldIsValid.bind(this)"></app-select-search>
        <app-select-search
          [readonly]="submittedSuccessfully"
          [label]="translateText('agevolazioni.classeFatturato')"
          nomecampo="classeDiFatturato"
          formcontrolname="classeDiFatturato"
          [arrayel]="listaClassiDiFatturato"
          [formgroup]="richiediAgevolazioniForm"
          [checkFieldIsValid]="checkFieldIsValid.bind(this)"></app-select-search>

        <div class="flex flex-col gap-2 justify-end">
          <label for="ragioneSociale" class="input-label-style">{{translateText('agevolazioni.ragioneSociale')}}</label>
          <input
            type="text"
            id="ragioneSociale"
            class="input-style"
            formControlName="ragioneSociale"
            [class.readonly]="submittedSuccessfully" />
          <p
            class="text-xs text-rosso-scaduto"
            [class.invisible]="checkFieldIsValid(richiediAgevolazioniForm, 'ragioneSociale')">
            {{translateText('agevolazioni.ragioneSocialeValida')}}
          </p>
        </div>
        <div class="flex flex-col gap-2 justify-end">
          <label for="codiceFiscale" class="input-label-style">{{translateText('agevolazioni.codiceFiscale')}}</label>
          <input
            type="text"
            id="codiceFiscale"
            class="input-style"
            formControlName="codiceFiscale"
            [class.readonly]="submittedSuccessfully" />
          <p
            class="text-xs text-rosso-scaduto"
            [class.invisible]="checkFieldIsValid(richiediAgevolazioniForm, 'codiceFiscale')">
            {{translateText('agevolazioni.codiceFiscaleValido')}}
          </p>
        </div>
        <div class="flex flex-col gap-2 justify-end">
          <label for="email" class="input-label-style">{{translateText('agevolazioni.email')}}</label>
          <input
            type="text"
            class="input-style"
            required
            id="email"
            formControlName="email"
            [class.readonly]="submittedSuccessfully" />
          <p
            class="text-xs text-rosso-scaduto"
            [class.invisible]="checkFieldIsValid(richiediAgevolazioniForm, 'email')">
            {{translateText('agevolazioni.emailValida')}}
          </p>
        </div>
        <div class="flex flex-col gap-2 justify-end">
          <label for="telefono" class="input-label-style">{{translateText('agevolazioni.telefono')}}</label>
          <input
            type="text"
            class="input-style"
            id="telefono"
            formControlName="telefono"
            [class.readonly]="submittedSuccessfully" />
          <p
            class="text-xs text-rosso-scaduto"
            [class.invisible]="checkFieldIsValid(richiediAgevolazioniForm, 'telefono')">
            Inserisci un numero di telefono valido
          </p>
        </div>
        <app-select-search
          [enabledSearch]="true"
          [readonly]="submittedSuccessfully"
          [checkFieldIsValid]="checkFieldIsValid.bind(this)"
          [label]="translateText('agevolazioni.cameraCommercio')"
          nomecampo="cameraDiCommercio"
          formcontrolname="cameraDiCommercio"
          [arrayel]="listaCamere"
          [formgroup]="richiediAgevolazioniForm"></app-select-search>
        <app-select-search
          [enabledSearch]="true"
          [readonly]="submittedSuccessfully"
          [checkFieldIsValid]="checkFieldIsValid.bind(this)"
          [label]="translateText('agevolazioni.settori')"
          nomecampo="settore"
          formcontrolname="settori"
          [arrayel]="listaSettori"
          [formgroup]="richiediAgevolazioniForm"
          [maxselection]="3"
          [multiple]="true"></app-select-search>
        <app-select-search
          [enabledSearch]="true"
          [readonly]="submittedSuccessfully"
          [checkFieldIsValid]="checkFieldIsValid.bind(this)"
          [label]="translateText('agevolazioni.agevolazioni')"
          nomecampo="finalita"
          formcontrolname="finalita"
          [arrayel]="listaFinalita"
          [formgroup]="richiediAgevolazioniForm"
          [maxselection]="3"
          [multiple]="true"
          [tooltip]="translateText('agevolazioni.indicaAttivitaObiettivi')"></app-select-search>
        <app-select-search
          [readonly]="submittedSuccessfully"
          [checkFieldIsValid]="checkFieldIsValid.bind(this)"
          [label]="translateText('agevolazioni.dimensioneInvestimento')"
          nomecampo="dimensioneInvestimento"
          formcontrolname="dimensioneInvestimento"
          [arrayel]="listaDimensioniInvestimento"
          [formgroup]="richiediAgevolazioniForm"></app-select-search>
      </div>
      <div class="flex flex-col gap-2 descrizione justify-end">
        <label for="descrizione" class="input-label-style">{{translateText('agevolazioni.ideaBusiness')}}</label>
        <textarea
          class="form-input-union-style w-full min-h-[80px] rounded-[30px] px-4"
          id="descrizione"
          [maxlength]="descrizioneMaxLength"
          formControlName="descrizione"
          [class.readonly]="submittedSuccessfully"></textarea>
          <p class="text-xs">{{translateText('agevolazioni.caratteriCounter')}}: <i [ngClass]="calculateColorMessage()">{{getDescrizioneLength() }}/{{descrizioneMaxLength}}</i></p>

        <p
          class="text-xs text-rosso-scaduto"
          *ngIf="!checkFieldIsValid(richiediAgevolazioniForm, 'descrizione')">
          {{translateText('agevolazioni.inserisciLaTuaIdea')}}
        </p>
      </div>

      <div class="inline-flex gap-2">
        <input
          type="checkbox"
          class="border-2 border-solid border-innexta-cyan p-4 w-10 h-10"
          [class.readonly]="submittedSuccessfully"
          formControlName="checkPrivacy"
          id="checkPrivacy" />
        <label for="checkPrivacy" class="font-light"
          >{{translateText('agevolazioni.consensoPrivacy')}}
          <a
            href="../../../../assets/pdf/{{translateText('agevolazioni.nomeFileInformativaPrivacy')}}"
            target="_blank"
            class="text-black font-bold hover:text-innexta-cyan"
            >{{translateText('agevolazioni.privacy')}}</a
          >
        </label>
      </div>
      <p
        class="text-xs text-rosso-scaduto"
        [class.invisible]="checkFieldIsValid(richiediAgevolazioniForm, 'checkPrivacy')">
        {{translateText('agevolazioni.accettaLaPrivacy')}}
      </p>

      <div class="flex gap-2 flex-col">
        <label for="checkMarketingUnionCamere" class="font-light text-justify"
          >{{translateText('agevolazioni.consensoEventi')}}
        </label>
        <div>
          <mat-radio-group
            aria-label="Accetta o rifiuta proposte marketing dalle Camere di Commercio"
            class="inline-flex flex-row rounded-md pr-2"
            id="checkMarketingUnionCamere"
            [class.readonly]="submittedSuccessfully"
            formControlName="checkMarketingUnionCamere">
            <mat-radio-button [value]="true">{{translateText('agevolazioni.si')}}</mat-radio-button>
            <mat-radio-button [value]="false">{{translateText('agevolazioni.no')}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <p
        class="text-xs text-rosso-scaduto"
        [class.invisible]="
          checkFieldIsValid(richiediAgevolazioniForm, 'checkMarketingUnionCamere')
        ">
        {{translateText('agevolazioni.accettaComunicazioniCamCom')}}
      </p>
      <span class="h-1"></span>
      <div class="flex gap-2 flex-col">
        <label for="checkMarketingInnexta" class="font-light text-justify"
          >{{translateText('agevolazioni.consensoComunicazioni')}}
        </label>
        <div>
          <mat-radio-group
            aria-label="Accetta o rifiuta proposte marketing da Innexta"
            class="inline-flex flex-row rounded-md pr-2"
            id="checkMarketingInnexta"
            [class.readonly]="submittedSuccessfully"
            formControlName="checkMarketingInnexta">
            <mat-radio-button [value]="true">{{translateText('agevolazioni.si')}}</mat-radio-button>
            <mat-radio-button [value]="false">{{translateText('agevolazioni.no')}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <p
        class="text-xs text-rosso-scaduto"
        [class.invisible]="checkFieldIsValid(richiediAgevolazioniForm, 'checkMarketingInnexta')">
        {{translateText('agevolazioni.accettaComunicazioniInnexta')}}
      </p>

      <div class="inline-flex gap-2">
        <label class="font-light"
          >
          {{translateText('agevolazioni.informazioniGenerali')}}
          <a
          href="../../../../assets/pdf/{{translateText('agevolazioni.nomeFileCondizioniGenerali')}}"
            target="_blank"
            class="text-black font-bold hover:text-innexta-cyan"
            >{{translateText('agevolazioni.cliccaQui')}}</a
          >
        </label>
      </div>

      <div class="inline-flex gap-2">

        <label  class="font-light"
          >{{translateText('agevolazioni.informazioniPrivacy')}}
          <a
          href="../../../../assets/pdf/{{translateText('agevolazioni.nomeFilePolitichePrivacy')}}"
            target="_blank"
            class="text-black font-bold hover:text-innexta-cyan"
            >{{translateText('agevolazioni.cliccaQui')}}</a
          >
        </label>
      </div>


      <div class="flex flex-col gap-2 mt-4 items-center w-full" *ngIf="!submittedSuccessfully">
        <div>
          <button type="submit" class="submit-btn h-10" (click)="onSubmitRichiesta()">
            {{translateText('agevolazioni.inviaRichiesta')}}
          </button>
        </div>
      </div>
      <div class="flex flex-col gap-2 mt-4 items-center" *ngIf="submittedSuccessfully">
        <p class="text-lg text-green-500">{{translateText('agevolazioni.richiestaInviataConSuccesso')}}</p>
        <div>
          
        </div>
      </div>
    </form>
  </div>
</div>

<footer *ngIf="embedded" class="flex flex-col border-t-4 border-innexta-cyan border-solid">
  <div class="flex flex-col sm:flex-row">
    <div class="flex-1 bg-white flex items-center flex-col pb-5">
      <img
        class="mb-2 h-14 max-h-14 mt-2"
        src="../../../assets/svg/logo-union-camere-testo-nero.svg"
        alt="Unioncamere" />
    </div>
    <div class="flex-1 bg-innexta-footer-black flex flex-row items-center footer-innexta-clip">
      <div style="color: white" class="ml-40">Powered by</div>
      <img
        class="h-full max-h-14 mb-2 mt-2"
        src="../../../assets/img/loghi/innexta-logo-hor-negative-RGB.png"
        alt="INNEXTA" />
    </div>
  </div>
</footer>
