<button class="open-menu-button">
  {{ filter.name }}
  <div class="dropdown-menu">
    <ul class="flex flex-col gap-5">
      @if(filter.values.length > 0){
      <li><input type="text" placeholder="Cerca attributo" [(ngModel)]="searchText" (ngModelChange)="searchFilter($event)" /></li>

      @for(filter_element of subFilterElements; track filter_element.id){
      <li
        (click)="changeFilterAttributeStatus(filter, filter_element)"
        class="text-left w-full whitespace-nowrap hover:shadow-xl"
        role="button">
        <div
          [class.invisible]="!checkFilterAttributeStatus(filter, filter_element)"
          class="inline-flex h-[14px] w-[14px]">
          <mat-icon
            style="font-size: 14px; height: 14px; width: 14px"
            fontIcon="check"
            class="absolute text-green-500"
            >check</mat-icon
          >
        </div>
        {{ filter_element.name }}

        <div class="float-right min-w-[50px] text-right font-bold">
          {{ filter_element.conteggio }}
        </div>
      </li>
      }@empty {
      <li>Nessun valore trovato</li>
      } }@else{
      <li>Nessun valore disponibile</li>
      }
    </ul>
  </div>
</button>
<ul class="lista-selezionati">
  <li
    *ngFor="let filter_element of getFilterActiveValuesFromArray(filter)"
    (click)="changeFilterAttributeStatus(filter, filter_element)">
    {{ filter_element.name }}
    <div class="float-right min-w-[50px] text-right font-bold">
      {{ filter_element.conteggio }}
    </div>
  </li>
</ul>
<button
  class="reset-button"
  *ngIf="getSelectedValues(filter).length > 0"
  (click)="clickButtonResetSpecificFilter(filter)">
  X
</button>
