import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Filter, FilterValue } from 'src/app/core/models/filter.model';

@Component({
  selector: 'app-filtro-ricerca-bandi-singolo',
  templateUrl: './filtro-ricerca-bandi-singolo.component.html',
  styleUrl: './filtro-ricerca-bandi-singolo.component.scss',
})
export class FiltroRicercaBandiSingoloComponent implements OnChanges {
  @Input({ required: true }) filter!: any;
  @Input({ required: true }) values!: any;

  @Input({ required: true }) changeFilterAttributeStatus: any;
  @Input({ required: true }) clickButtonResetSpecificFilter: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['values']) {
      this.subFilterElements = this.values;
      this.searchText = '';
    }
  }
  searchText: string = '';
  subFilterElements: any = [];
  searchFilter(event: string) {
    this.subFilterElements = this.filter.values.filter((value: { name: string }) =>
      value.name.toLowerCase().includes(event.toLowerCase())
    );
  }
  getSelectedValues(filter: Filter) {
    return [...filter.actived_value.values()];
  }

  checkFilterAttributeStatus(filter: Filter, filter_value: FilterValue) {
    return filter.actived_value.has(filter_value.id);
  }
  getFilterActiveValuesFromArray(filter: Filter) {
    return filter.values.filter(value => this.checkFilterAttributeStatus(filter, value));
  }
}
