import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { GoBackGuardDialogComponent } from './go-back-guard-dialog/go-back-guard-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-go-back-btn',
  templateUrl: './go-back-btn.component.html',
  styleUrls: ['./go-back-btn.component.scss'],
  imports:[MatDialogModule,MatIconModule,RouterLink],
  standalone: true,
})
export class GoBackBtnComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}
  @Input() goBack = () => {
    this.router.navigate(['../'], { relativeTo: this.route });
  };
  @Input() link?: string;

  @Input() guard_function = () => true;
  @Input() guard_message =
    'Sei sicuro di voler tornare indietro? Le modifiche non salvate andranno perse.';
  goBackClick() {
    if (!this.guard_function()) {
      this.openGuardDialog();
      return;
    }
    this.goBackActivate();
  }
  goBackActivate() {
    if (this.link) {
      this.router.navigateByUrl(this.link);
      return;
    }
    this.goBack();
  }
  openGuardDialog() {
    const dialogRef = this.dialog.open(GoBackGuardDialogComponent, {
      data: { guard_message: this.guard_message },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.action) this.goBackActivate();
    });
  }
}
