import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KEY_ACCESS_TOKEN, KEY_BANDI_FILTER, KEY_LISTA_RICHIESTE_FILTER, KEY_USER_DATA } from '../../costanti_sessione';
import { RUOLO_ADMIN, RUOLO_CONSULENTE, RUOLO_OPERATORE, RUOLO_OSPITE } from '../../costanti_ruoli';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {



  constructor(private router: Router) {
    this.access_token = localStorage.getItem(KEY_ACCESS_TOKEN);
  }

  private access_token: string | null;
  private user?: any;
  public setUserData(user: any): void {
    this.user = user;
  }
  public setAccessToken(token: string): void {
    this.access_token = token;
    localStorage.setItem(KEY_ACCESS_TOKEN, token);
  }

  public getUserData(): any {
    return this.user;
  }
  public getAccessToken(): string | null {
    return this.access_token;
  }
  public isLogged(): boolean {
    if (!this.access_token) return false;
    return true;
  }
  public isUserLoaded(): boolean {
    return this.user !== undefined;
  }
  public get role(){
    return this.user?.ruoli[0].ruolo_nome ?? RUOLO_OSPITE;
  }
  public isConsulente(): boolean {
    return this.role === RUOLO_CONSULENTE;
  }

  public isOperatoreCamera(): boolean {
    return this.role === RUOLO_OPERATORE;
  }

  public logout(): void {
    this.access_token = null;
    this.user = undefined;
    localStorage.removeItem(KEY_ACCESS_TOKEN);
    localStorage.removeItem(KEY_BANDI_FILTER);
    localStorage.removeItem(KEY_USER_DATA);
    localStorage.removeItem(KEY_LISTA_RICHIESTE_FILTER)
    this.router.navigate(['/signin']);
  }

  public isAdmin(): boolean {
    return this.role === RUOLO_ADMIN;
  }

  public get accreditata(){
    if (this.user?.cameraDiCommercio)
      return this.user?.cameraDiCommercio?.accreditata;
    else
      return false;
  }
}
