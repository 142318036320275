import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TipologicaBase } from 'src/app/core/models/tipologiche.model';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrl: './select-search.component.scss',
})
export class SelectSearchComponent implements OnInit, OnChanges {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) nomecampo!: string;
  @Input({ required: true }) formcontrolname!: string;
  @Input({ required: true }) arrayel!: TipologicaBase[];
  arrayelFiltered!: TipologicaBase[];
  @Input({ required: true }) formgroup!: FormGroup;
  @Input() maxselection?: number;
  @Input() tooltip?: string;

  @Input() multiple: boolean = false;
  @Input() checkFieldIsValid!: Function;

  @Input() readonly: boolean = false;
  @Input() enabledSearch: boolean = false;
  ngOnChanges(changes: SimpleChanges) {
    if (changes['arrayel']) {
      this.arrayelFiltered = this.arrayel;
    }
    this.translate
      .get([
        'agevolazioni.selezionareUnaVoce',
        'agevolazioni.selezionareAlmenoUnaVoce',
      ])
      .subscribe(translations => {
        this.selezionareUnaVoce = translations['agevolazioni.selezionareUnaVoce'];
        this.selezionareAlmenoUnaVoce = translations['agevolazioni.selezionareAlmenoUnaVoce'];
      });
  }

  selezionareUnaVoce: string = '';
  selezionareAlmenoUnaVoce: string = '';

  checkNumberSelectedIsUnderMax(elId: string | number) {
    if (this.formgroup.value[this.formcontrolname] == null) return false;
    if (this.formgroup.value[this.formcontrolname].includes(elId)) return false;
    return this.formgroup.value[this.formcontrolname].length >= this.maxselection!;
  }
  constructor(private translate: TranslateService) {}
  ngOnInit(): void {
    this.arrayelFiltered = this.arrayel;
  }

  search(event: string) {
    console.log(event);
    if (event === '') {
      this.arrayelFiltered = this.arrayel;
      return;
    }
    this.arrayelFiltered = this.arrayel.filter((el: TipologicaBase) => {
      if (
        Array.isArray(this.formgroup.value[this.formcontrolname]) &&
        this.formgroup.value[this.formcontrolname].includes(el.id)
      )
        return true;

      return el.nome.toLowerCase().includes(event.toLowerCase());
    });
  }
}
